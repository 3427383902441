let EventEmitter = require("events").EventEmitter;

/**
 * アセット管理用クラス
 */
class AssetsManager extends EventEmitter {

  // ---------------------------------------------------------------------------------------------------------
	// --	AssetsManager
	// ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor() {
    super();
    console.log("AssetsManager");
  }

  // ----------------------------------------------------------------------------------------------------------------
  // --	ロード
  // ----------------------------------------------------------------------------------------------------------------
  load(assets)
  {
    for(var i=0; i<assets.length; i++)
    {
      var asset = assets[i];
      PIXI.loader.add(asset.name, asset.url);
    }

    PIXI.loader.load(this.onComplete.bind(this));
  }

  onComplete(loader, resources)
  {
    this.loadedresources = resources;

    console.log(this.loadedresources);

    this.emit("complete");
  }


  // ----------------------------------------------------------------------------------------------------------------
  // --	テクスチャ取得
  // ----------------------------------------------------------------------------------------------------------------
  getTexture(name, antialiasing){
    var texture = PIXI.Texture.fromFrame(name);
    texture.baseTexture.scaleMode = antialiasing?PIXI.SCALE_MODES.LINEAR:PIXI.SCALE_MODES.NEAREST;
    return texture;
  }

  // ----------------------------------------------------------------------------------------------------------------
  // --	スプライト生成
  // ----------------------------------------------------------------------------------------------------------------
  createSprite(name, antialiasing){
    var sprite = new PIXI.Sprite(this.getTexture(name, antialiasing));
    return sprite;
  }

  // ----------------------------------------------------------------------------------------------------------------
  // --	ムービークリップ生成
  // ----------------------------------------------------------------------------------------------------------------
  createMovieClip(name, antialiasing, animationSpeed, loop, singleframe, uniqname)
  {
    //変数宣言
    let framecount = -1;
    let frametextures = [];

    //シングルフレームの場合
    if(singleframe)
    {
      frametextures.push(this.getTexture(name, antialiasing));
    }
    //複数フレームの場合
    else
    {
      const frames = this.loadedresources["ssjson"].data.frames;

      for(let key in frames)
      {
        if(key.indexOf(name) == 0)
        {
          framecount ++;

          var val = framecount;
          if(val < 10)
          {
            val = "000" + val;
          }
          else if(val < 100)
          {
            val = "00" + val;
          }
          else if(val < 1000)
          {
            val = "0" + val;
          }

          frametextures.push(this.getTexture(name + val, antialiasing));
        }
      }
    }

    //ムービークリップ生成
    let mc = new PIXI.extras.AnimatedSprite(frametextures);
    mc.animationSpeed = animationSpeed;
    mc.loop = loop;
    mc.name = name;
    if(uniqname) mc.name = uniqname;

    return mc;
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default new AssetsManager();
