import AssetsManager from './util/AssetsManager';

class Ball extends PIXI.Container
{
  // ---------------------------------------------------------------------------------------------------------
	// --	Ball
	// ---------------------------------------------------------------------------------------------------------

  constructor(){
    super();

    //画像読み込み
    const ball = AssetsManager.createSprite("ball", true);
    ball.anchor.set(0.5, 0.5);
    ball.scale.set(0.15, 0.15);
    this.addChild(ball);

    //初期位置
    const wW = document.getElementById("illust-container").clientWidth+200;
    this.y = -600 - (Math.random()*30000)/100;
    this.x = wW/2;
    if(this.x < 250) this.x = 250;
    this.speedX = -5 - (Math.random()*500)/100;
    this.speedY = -5-(Math.random()*1500)/100;

    //反対に
    if(Math.random()*100<50)
    {
      this.x *= -1;
      this.speedX *= -1;
    }
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	動き
	// ---------------------------------------------------------------------------------------------------------

  update(){

    //重力
    this.speedY += 0.5;

    //移動
    this.x += this.speedX;
    this.y += this.speedY;

    //回転
    this.rotation += (this.speedX * (Math.PI / 180));

    //バウンド
    if(this.y >= -50 && this.speedY > 0)
    {
      this.y = -50;
      this.speedY *= -0.9;
    }
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default Ball;
