import AssetsManager from './util/AssetsManager';
import Racket from './Racket';
import Ball from './Ball';


class App
{
  // ---------------------------------------------------------------------------------------------------------
	// --	App
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //変数
    this.frameCount = -1;

    //解像度
  	var resolution = window.devicePixelRatio > 1 ? 2 : 1;
    console.log("window.devicePixelRatio:" + window.devicePixelRatio);
  	PIXI.settings.RESOLUTION = resolution;

    //素材読み込み
    this.loadAssets();
  }

  loadAssets(){
    AssetsManager.load([{name:"ssjson", url:"./assets/img/ss.json"}]);
    AssetsManager.once("complete", ()=>{
      this.initPixi();
    });
  }

  /**
   * PIXI.js初期化
   */
  initPixi(){

    console.log("initPixi");

    //変数初期化
    this.wW = 0;
    this.wH = 0;

    //アプリケーション
		this.pixi = new PIXI.Application(1, 1, { transparent:true, forceCanvas:true});
    this.pixi.ticker.add(this.update.bind(this));

    const lm = new PIXI.interaction.InteractionManager(this.pixi.renderer, {autoPreventDefault:false});
    console.log("lm.autoPreventDefault:" + lm.autoPreventDefault);
    // lm.autoPreventDefault = false;

    //コンテナ配置
    this.container = new PIXI.Container();
    this.pixi.stage.addChild(this.container);

    //草配置
    for(let i=0; i<3; i++)
    {
      //草生成
      const kusa = AssetsManager.createSprite("kusa", true);
      kusa.anchor.set(0.5, 1);
      kusa.scale.set(0.1, 0.1);
      this.container.addChild(kusa);

      //位置
      switch (i) {
        case 1:
          kusa.x = -250;
          kusa.y = -100;
          break;
        case 2:
          kusa.x = 250;
          kusa.y = -100;
          break;
      }
    }

    //ラケット配置
    this.racket = new Racket();
    this.racket.y = -80;
    this.container.addChild(this.racket);

    //ボールコンテナ
    this.ballContainer = new PIXI.Container();
    this.container.addChild(this.ballContainer);

    //VIEW追加
    document.getElementById("illust-container").appendChild(this.pixi.view);

    //キャンバスサイズ初期化
    this.updateCanvasSize();
    window.addEventListener('resize', ()=>{
      this.updateCanvasSize();
    });

    //ボール生成
    TweenMax.delayedCall(3.0 + (Math.random()*700)/100, this.createBall.bind(this));
  }

  // ---------------------------------------------------------------------------------------------------------

  createBall(){

    //ボール配置
    this.ball = new Ball();
    this.ball.y = -200;
    this.ballContainer.addChild(this.ball);

    //ボール生成
    TweenMax.delayedCall(1.0 + (Math.random()*400)/100, this.createBall.bind(this));
  }

  // ---------------------------------------------------------------------------------------------------------

  /**
   * 更新
   */
  update(){

    //ラケット更新
    if(this.racket) this.racket.update();

    //ボール更新
    const len = this.ballContainer.children.length;
    for(let i=0; i<len; i++)
    {
      let ball = this.ballContainer.getChildAt(i);
      ball.update();
    }
  }

  // ---------------------------------------------------------------------------------------------------------

  //キャンバスサイズをアップデート
	updateCanvasSize(){

    //Windowサイズを保持
    let preW = this.wW;
    let preH = this.wH;
    this.wW = document.getElementById("illust-container").clientWidth;
    this.wH = document.getElementById("illust-container").clientHeight;

    //リサイズ
    if(preW != this.wW || preH != this.wH)
    {
      //レンダラーのリサイズ
  		this.pixi.renderer.resize(this.wW, this.wH);

      //コンテナの位置
      this.container.x = this.wW/2;
      this.container.y = this.wH;
    }
	}

  // ---------------------------------------------------------------------------------------------------------
}


$(function(){
  let app = new App();
});
