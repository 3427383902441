import AssetsManager from './util/AssetsManager';

class Racket extends PIXI.Container
{
  // ---------------------------------------------------------------------------------------------------------
	// --	Racket
	// ---------------------------------------------------------------------------------------------------------

  constructor(){
    super();

    //体配置
    this.bodyContainer = new PIXI.Container();
    this.bodyContainer.rotation = 0.0;
    this.addChild(this.bodyContainer);

    //体
    this.body = AssetsManager.createMovieClip("body_blink", true, 0.1, true, false, "blink");
    this.body.anchor.set(0.5, 1.0);
    this.body.scale.set(0.2, 0.2);
    // this.body.play();
    this.body.gotoAndStop(0);
    this.bodyContainer.addChild(this.body);

    //左腕
    this.armL = AssetsManager.createSprite("armL", true);
    this.armL.anchor.set(0.0, 0.0);
    this.armL.scale.set(0.2, 0.2);
    this.armL.x = 7;
    this.armL.y = -35;
    this.bodyContainer.addChild(this.armL);

    //右腕
    this.armR = AssetsManager.createSprite("armR", true);
    this.armR.anchor.set(1.0, 0.0);
    this.armR.scale.set(0.2, 0.2);
    this.armR.x = -7;
    this.armR.y = -36;
    this.bodyContainer.addChild(this.armR);

    //左脚
    this.legL = AssetsManager.createSprite("legL", true);
    this.legL.anchor.set(0.3, 0.0);
    this.legL.scale.set(0.2, 0.2);
    this.legL.x = 5;
    this.legL.y = -3;
    this.legL.rotation = 0.1;
    this.addChild(this.legL);

    //右脚
    this.legR = AssetsManager.createSprite("legR", true);
    this.legR.anchor.set(0.7, 0.0);
    this.legR.scale.set(-0.2, 0.2);
    this.legR.x = -2;
    this.legR.y = -3;
    this.legR.rotation = 0.1;
    this.addChild(this.legR);

    //初期速度
    this.speedX = 1;
    // TweenMax.delayedCall(3.0 + (Math.random()*700)/100, this.changeDir.bind(this));

    //歩く
    this.loop();

    //まばたき
    const delay = 1.0 + (Math.random()*300)/100;
    TweenMax.delayedCall(delay, this.blink.bind(this));
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	動き
	// ---------------------------------------------------------------------------------------------------------

  update(){

    this.x += this.speedX;
    this.scale.x = (this.speedX > 0)?1:-1;

    //移動範囲
    const wW = (document.getElementById("illust-container").clientWidth-100);
    let limitX = wW/2;
    if(limitX > 200) limitX = 200;

    //方向転換
    if(this.speedX > 0 && this.x > limitX)
    {
      this.x = limitX;
      this.speedX *= -1;
    }
    else if(this.speedX < 0 && this.x < -limitX)
    {
      this.x = -limitX;
      this.speedX *= -1;
    }
  }

  changeDir(){
    this.speedX *= -1;
    TweenMax.delayedCall(3.0 + (Math.random()*700)/100, this.changeDir.bind(this));
  }



  // ---------------------------------------------------------------------------------------------------------
	// --	アニメーション
	// ---------------------------------------------------------------------------------------------------------

  /**
   * ループアニメーション
   */
  loop(){

    const duration = 0.3;

    TweenMax.to(this.bodyContainer, duration, {rotation:0.05, y:3, delay:duration*0, ease:Power0.easeNone});
    TweenMax.to(this.bodyContainer, duration, {rotation:0.0, y:0, delay:duration*1, ease:Power0.easeNone});
    TweenMax.to(this.bodyContainer, duration, {rotation:-0.05, y:3, delay:duration*2, ease:Power0.easeNone});
    TweenMax.to(this.bodyContainer, duration, {rotation:0.0, y:0, delay:duration*3, ease:Power0.easeNone});

    TweenMax.to(this.legL, duration, {rotation:-0.3, x:7, delay:duration*0, ease:Power0.easeNone});
    TweenMax.to(this.legL, duration, {rotation:0.1, x:5, delay:duration*1, ease:Power0.easeNone});
    TweenMax.to(this.legL, duration, {rotation:0.5, x:3, delay:duration*2, ease:Power0.easeNone});
    TweenMax.to(this.legL, duration, {rotation:0.1, x:5, delay:duration*3, ease:Power0.easeNone});

    TweenMax.to(this.legR, duration, {rotation:0.5, x:-4, delay:duration*0, ease:Power0.easeNone});
    TweenMax.to(this.legR, duration, {rotation:0.1, x:-2, delay:duration*1, ease:Power0.easeNone});
    TweenMax.to(this.legR, duration, {rotation:-0.3, x:0, delay:duration*2, ease:Power0.easeNone});
    TweenMax.to(this.legR, duration, {rotation:0.1, x:-2, delay:duration*3, ease:Power0.easeNone});

    TweenMax.to(this.armL, duration, {rotation:-0.1, delay:duration*0, ease:Power0.easeNone});
    TweenMax.to(this.armL, duration, {rotation:0.0, delay:duration*1, ease:Power0.easeNone});
    TweenMax.to(this.armL, duration, {rotation:0.1, delay:duration*2, ease:Power0.easeNone});
    TweenMax.to(this.armL, duration, {rotation:0.0, delay:duration*3, ease:Power0.easeNone});

    TweenMax.to(this.armR, duration, {rotation:-0.1, delay:duration*0, ease:Power0.easeNone});
    TweenMax.to(this.armR, duration, {rotation:0.0, delay:duration*1, ease:Power0.easeNone});
    TweenMax.to(this.armR, duration, {rotation:0.1, delay:duration*2, ease:Power0.easeNone});
    TweenMax.to(this.armR, duration, {rotation:0.0, delay:duration*3, ease:Power0.easeNone});

    TweenMax.delayedCall(duration*4, this.loop.bind(this));
  }


  /**
   * まばたきアニメーション
   */
  blink(){

    this.body.play();

    TweenMax.delayedCall(0.8, ()=>{
      this.body.gotoAndStop(0);

      const delay = 1.0 + (Math.random()*300)/100;
      TweenMax.delayedCall(delay, this.blink.bind(this));

    });
  }


  // ---------------------------------------------------------------------------------------------------------
}

export default Racket;
